import React from "react";
import * as styles from "./404.module.scss";
import Seo from "../components/layout/SEO";
import logoMark from "../images/logo.svg";

// markup
const NotFoundPage = () => {
  return (
    <>
      <Seo title="404 Not Found" />
      <main className={styles.container}>
        <div>
          <img
            src={logoMark}
            alt="Simplicitic Logo"
            className={styles.logo}
          ></img>
        </div>
        <title className="h3">Something's Not Right</title>
        <p>
          We can't find the page you are looking for. You may head back to home.
          <a href="/" className="btn">
            Go home
          </a>
        </p>
      </main>
    </>
  );
};

export default NotFoundPage;
